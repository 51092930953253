import bat1 from 'art/bat1.png';
import bat2 from 'art/bat2.png';
import bat3 from 'art/bat3.png';
import bat4 from 'art/bat4.png';
import { useInterval } from 'hooks';
import React, { useState } from 'react';
import styled from 'styled-components';

const WIDTH = '20rem';

const BatCave = styled.div`
  position: relative;
  width: 20rem;
  height: 13.75rem;
  margin-top: ${(props) => props.loading && '10rem'};
`;

const Bat1 = styled.img.attrs(() => ({
  src: bat1,
  alt: 'Bat Flapping wings',
}))`
  display: flex;
  justify-content: center;
  width: ${WIDTH};
  height: auto;
  opacity: ${({ active }) => (active ? 1 : 0)};
  position: absolute;
  top: -3.125rem;
`;

const Bat2 = styled(Bat1).attrs(() => ({
  src: bat2,
  alt: 'Bat Flapping wings',
}))``;

const Bat3 = styled(Bat1).attrs(() => ({
  src: bat3,
  alt: 'Bat Flapping wings',
}))``;
const Bat4 = styled(Bat1).attrs(() => ({
  src: bat4,
  alt: 'Bat Flapping wings',
}))``;

const BatFlapping = ({ loading, milliseconds = 110 }) => {
  const [activeBat, setActiveBat] = useState(1);
  const [isIncrease, setIsIncrease] = useState(true);

  const setNextBat = () => {
    setActiveBat((_activeBat) => {
      if (_activeBat === 2) {
        setIsIncrease(true);
      } else if (_activeBat === 3) {
        setIsIncrease(false);
      }
      if (isIncrease) {
        return _activeBat + 1;
      } else {
        return _activeBat - 1;
      }
    });
  };

  useInterval(setNextBat, milliseconds);

  return (
    <BatCave loading={loading}>
      <Bat1 active={activeBat === 1} />
      <Bat2 active={activeBat === 2} />
      <Bat3 active={activeBat === 3} />
      <Bat4 active={activeBat === 4} />
    </BatCave>
  );
};

export default BatFlapping;
