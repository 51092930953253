import React, { useState } from 'react';
import Game from 'containers/Game';
import { orange, red, white } from 'core/styles';
import styled from 'styled-components';
import { processText } from 'core/utils/string';
import { web } from 'submodules/sharedText/flavor';

const { waitingRoom, actions } = web;
const flavor = { ...waitingRoom, ...actions };

const Intro = ({ match }) => {
  const { role, dayNight, title, text, isVip } = match?.params || {};
  const [isLoading, setIsLoading] = useState(false);
  const onClick = () => setIsLoading(true);
  const options = [
    {
      text: processText(flavor.startGame),
      onClick,
      id: 0,
    },
    {
      text: processText(flavor.startGameWithIntro),
      onClick,
      id: 1,
    },
    {
      text: processText(flavor.addPlayers),
      onClick,
      id: 2,
    },
    {
      text: processText(flavor.quit),
      onClick,
      id: 3,
    },
  ];

  return (
    <Game
      title={title}
      content={text}
      options={isVip && options}
      isLoading={isLoading}
      role={role}
      isNight={dayNight}
      isLongFlipped={!isVip}
      validGameSession
      name
    />
  );
};

export default Intro;
