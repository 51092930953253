import React, { useState } from 'react';
import Game from 'containers/Game';

const NewDesigns = ({ match }) => {
  const { role, night, version = '' } = match?.params || {};
  const isNight = night === 'night';
  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const [content, setContent] = useState('Vote on who to stake next');
  const [title, setTitle] = useState(isNight ? 'Night' : 'Day');
  const onClick = () => {
    setTimeout(() => {
      setIsExpired(true);
      setIsLoading(false);
      setContent("You're dead. Now go haunt your friends");
      setTitle('');
    }, 3000);
    setIsLoading(true);
  };

  const options = [
    {
      text: 'Belinda',
      id: 1,
      onClick,
    },
    {
      text: 'Georgiana',
      id: 2,
      onClick,
    },
    {
      text: 'Eliza',
      id: 3,
      onClick,
    },
    {
      text: 'Helen',
      id: 4,
      onClick,
    },
    {
      text: 'Artie',
      id: 5,
      onClick,
    },
  ];

  return (
    <Game
      title={title}
      content={content}
      options={options}
      isNight={isNight}
      role={role + version}
      isLoading={isLoading}
      isExpired={isExpired}
      validGameSession
      name
    />
  );
};

export default NewDesigns;
