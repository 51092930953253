import Button from 'components/Button/v2';
import Started from 'containers/Game/Started';
import { actions } from 'core/constants';
import { colorLoop } from 'core/styles';
import { getIsVamp } from 'core/utils/roles';
import { processText } from 'core/utils/string';
import React, { useEffect, useState } from 'react';
import { web } from 'submodules/sharedText/flavor';

const { night } = web;
const {
  content: { vampireVote },
} = night;

const {
  GET_VAMPIRE_TARGET_POLLS,
  VAMPIRE_HUNT,
  VAMPIRE_MESSAGE,
  ON_UPDATE_VAMPIRE_POLL,
} = actions;

const VampireVote = ({
  player,
  gameState,
  socket,
  gameSessionId,
  role,
  isExpired,
  playerVoted,
  image,
}) => {
  const {
    candidates,
    _id: playerId,
    vote: playerVote,
    hasSuperPower,
    message,
  } = player || {};

  const isVamp = getIsVamp(role);
  const [poll, setPoll] = useState([]);
  const [vote, setVote] = useState(playerVote);

  const createVampireVote = (playerVote) => () => {
    if (playerVote === vote) {
      const payload = { id: playerId };
      socket.emit(VAMPIRE_MESSAGE, { gameSessionId, payload });
    } else {
      socket.emit(VAMPIRE_HUNT, { gameSessionId, playerId, vote: playerVote });
    }
    setVote(playerVote);
  };

  const confirmKill = (playerVote) => () => {
    socket.emit(VAMPIRE_HUNT, {
      gameSessionId,
      playerId,
      vote: playerVote,
      confirm: true,
    });
  };

  useEffect(() => {
    setVote(playerVote);
  }, [playerVote]);

  useEffect(() => {
    if (isVamp) {
      socket.emit(
        GET_VAMPIRE_TARGET_POLLS,
        {
          gameSessionId,
          playerId,
        },
        (response) => {
          if (response.status === 200) {
            setPoll(
              response.data.map((p, i) => ({
                ...p,
                triggered: false,
                color: colorLoop[i % colorLoop.length],
              }))
            );
          }
        }
      );
      socket.on(`${gameSessionId}-${ON_UPDATE_VAMPIRE_POLL}`, function (data) {
        setPoll((_polls) =>
          _polls.map((p) => (p.id === data.id ? { ...p, ...data } : p))
        );
      });
      // TODO: make the flashing more obvious!
      socket.on(`${gameSessionId}-${VAMPIRE_MESSAGE}`, function (data) {
        setPoll((_polls) =>
          _polls.map((p) =>
            p.id === data.id ? { ...p, ...data, triggered: true } : p
          )
        );
        setTimeout(() => {
          setPoll((_polls) =>
            _polls.map((p) =>
              p.id === data.id ? { ...p, ...data, triggered: false } : p
            )
          );
        }, 200);
      });
    }
  }, [socket, gameSessionId, playerId, isVamp, setPoll]);

  const canVote = vote && poll?.every((p) => p.vote === vote);
  // if (!hasSuperPower) {
  //   return <Text>{message}</Text>;
  // }
  // if (playerVoted) {
  //   const { userName } = candidates.find(({ _id }) => _id === vote);
  //   return <Text>{processText(flavor.voted, { name: userName })}</Text>;
  // }
  const options = candidates?.map(
    ({ _id, userName: potentialVictim, role }) => {
      const selectedPolls = poll
        .filter(({ vote }) => vote === _id)
        .map(({ color, triggered }) => ({ color, triggered }));
      const selected = vote === _id;
      return {
        text: potentialVictim,
        // onClick: canVote ? confirmKill(_id) : createVampireVote(_id),
        onClick: createVampireVote(_id),
        selectedPolls,
        selected,
      };
    }
  );
  return (
    <>
      <Started
        title={processText(night.title)}
        content={message || processText(vampireVote.instructions)}
        role={role}
        image={image}
        isLoading={playerVoted}
        isExpired={isExpired}
        options={hasSuperPower ? options : []}
      />
      {!playerVoted && canVote && (
        <Button onClick={confirmKill(vote)} selected>
          {processText(vampireVote.confirmVote)}
        </Button>
      )}
    </>
  );
};
export default VampireVote;
