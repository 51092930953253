import Staging, { URLS } from 'containers/Staging';
import Intro from 'containers/Staging/Intro';
import CharacterInfo from 'containers/Staging/CharacterInfo';
import NewDesigns from 'containers/Staging/NewDesigns';
import Login from 'containers/Staging/Login';
import StartGame from 'containers/Staging/StartGame';
import EndGame from 'containers/Staging/EndGame';
import { Home } from 'pages';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const Routes = ({ connected, socket }) => {
  return (
    <Switch>
      <Route exact path="/">
        <Home loading={!connected} socket={socket} />
      </Route>
      <Route exact path="/staging" component={Staging} />
      <Route exact path={URLS.VOTING} component={NewDesigns} />
      <Route exact path={URLS.INTRO} component={Intro} />
      <Route exact path={URLS.LOGIN} component={Login} />
      <Route exact path={URLS.START_GAME} component={StartGame} />
      <Route exact path={URLS.END_GAME} component={EndGame} />
      <Route exact path={URLS.CHARACTER_INFO} component={CharacterInfo} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};
export default Routes;
