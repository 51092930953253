import { Text } from 'components/Text';
import Title from 'components/Title';
import {
  getIsGarlicFarmer,
  getIsLibrarian,
  getIsMayor,
  getIsPriest,
  getIsVamp,
  getIsCullen,
  getIsHuman,
} from 'core/utils/roles';
import { processText } from 'core/utils/string';
import React from 'react';
import styled from 'styled-components';
import { web } from 'submodules/sharedText/flavor';
import GarlicFarmerVote from './GarlicFarmerVote';
import LibrarianResearch from './LibrarianResearch';
import MayorBallot from './MayorBallot';
import PriestBallot from './PriestBallot';
import VampireVote from './VampireVote';
import Started from 'containers/Game/Started';

const { night } = web;
const flavor = { ...night };

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Night = (props) => {
  const { role, image, isExpired } = props;
  // TODO: remove all this isRole shit --- just check against constants
  const isVamp = getIsVamp(role);
  const isGarlicFarmer = getIsGarlicFarmer(role);
  const isLibrarian = getIsLibrarian(role);
  const isPriest = getIsPriest(role);
  const isMayor = getIsMayor(role);
  const isHuman = getIsHuman(role);
  const isCullen = getIsCullen(role);

  return (
    <Wrapper>
      {/* TODO: Add flavor text for each character during this period... */}
      {(isHuman || isCullen) && (
        <Started
          title={processText(night.title)}
          content={processText(flavor.content.default[role])}
          role={role}
          image={image}
          isExpired={isExpired}
          isLongFlipped
        />
      )}
      {isVamp && <VampireVote {...props} />}
      {isGarlicFarmer && <GarlicFarmerVote {...props} />}
      {isLibrarian && <LibrarianResearch {...props} />}
      {isPriest && <PriestBallot {...props} />}
      {isMayor && <MayorBallot {...props} />}
    </Wrapper>
  );
};
export default Night;
