import Routes from 'containers/Routes';
import { black, paperWhite } from 'core/styles';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import io from 'socket.io-client';
import styled, { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import Gorey from './fonts/Gorey.ttf';

const isDev = process.env.NODE_ENV === 'development';
const socketUrl = isDev
  ? 'http://localhost:3001'
  : 'https://vampire-party-api.herokuapp.com/';

const socket = io(socketUrl);

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GlobalStyle = createGlobalStyle`
    ${reset}
    @font-face {
      font-family: 'Gorey';
      src: local('Gorey'), url(${Gorey}) format('truetype');
    }
    body {
      font-family: Gorey;
      padding: 0;
      line-height: 1.1;
      letter-spacing: 1.2px;
      background-color: ${paperWhite};
      color: ${black};
      font-size: 16px;
      user-select: none;
      overflow: visible;
    },
    input, textarea, button {
      font-family: Gorey;
    }
  `;
function App() {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    socket.on('connected-to-server', function ({ status }) {
      setConnected(status === 200);
    });
    socket.on('disconnect', () => {
      setConnected(false);
    });
  }, []);

  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Main>
          <Routes connected={connected} socket={socket} />
        </Main>
      </BrowserRouter>
    </>
  );
}

export default App;
