import BatFlapping from 'components/BatFlapping';
import CharacterInfo from 'components/CharacterInfo';
import Intro from 'components/Intro';
import Day from 'containers/Day';
import Expired from 'containers/Expired';
import EndGame from 'containers/EndGame';
import LoginForm from 'containers/LoginForm';
import Morning from 'containers/Morning';
import Night from 'containers/Night';
import Polls from 'containers/Polls';
import WaitingRoom from 'containers/WaitingRoom';
import React from 'react';

const Home = ({
  socket,
  delayedProps,
  onSubmitGameSession,
  _setGameSessionValue,
  gameSessionError,
  resetGameSession,
  gameSessionId,
  _setPlayerName,
  _setPlayerNameValue,
  playerNameValue,
  playerError,
  startGame,
  startGameWithIntro,
  player,
  newGameAddPlayers,
  quitRound,
}) => {
  if (delayedProps.loading)
    return <BatFlapping loading={delayedProps.loading} />;

  if (delayedProps.preGame)
    return (
      <>
        {!delayedProps.showLoginTransition && (
          <LoginForm
            onSubmitGameSession={onSubmitGameSession}
            onChangeGameSession={_setGameSessionValue}
            gameSessionError={gameSessionError}
            validGameSession={delayedProps.validGameSession}
            resetGameSession={resetGameSession}
            gameSessionId={gameSessionId}
            onSubmitTextInput={_setPlayerName}
            onChangeTextInput={_setPlayerNameValue}
            playerNameValue={playerNameValue}
            playerError={playerError}
          />
        )}
        {delayedProps.showLoginTransition && (
          <WaitingRoom
            playerName={delayedProps.playerNameValue}
            gameSessionId={gameSessionId}
            vipReady={delayedProps.vipReady}
            startGame={startGame}
            startGameWithIntro={startGameWithIntro}
          />
        )}
      </>
    );

  if (delayedProps.isIntro)
    return (
      <Intro
        player={player}
        socket={socket}
        gameState={delayedProps.isIntro}
        gameSessionId={gameSessionId}
        playerVoted={delayedProps.playerVoted}
        role={delayedProps.role}
        image={delayedProps.image}
        isExpired={delayedProps.isExpired}
      />
    );

  if (delayedProps.hasStarted)
    return (
      <CharacterInfo
        player={player}
        socket={socket}
        gameSessionId={gameSessionId}
        playerVoted={delayedProps.playerVoted}
        role={delayedProps.role}
        image={delayedProps.image}
        isExpired={delayedProps.isExpired}
      />
    );

  if (delayedProps.isNight)
    return (
      <Night
        player={player}
        socket={socket}
        gameSessionId={gameSessionId}
        playerVoted={delayedProps.playerVoted}
        message={delayedProps.message}
        role={delayedProps.role}
        image={delayedProps.image}
        isExpired={delayedProps.isExpired}
      />
    );

  if (delayedProps.isMorning)
    return (
      <Morning
        message={delayedProps.message}
        role={delayedProps.role}
        image={delayedProps.image}
        isExpired={delayedProps.isExpired}
      />
    );

  if (delayedProps.isDay)
    return (
      <Day
        player={player}
        socket={socket}
        gameSessionId={gameSessionId}
        playerVoted={delayedProps.playerVoted}
        message={delayedProps.message}
        role={delayedProps.role}
        image={delayedProps.image}
        isExpired={delayedProps.isExpired}
      />
    );

  if (delayedProps.isPolls)
    return (
      <Polls
        message={delayedProps.message}
        role={delayedProps.role}
        image={delayedProps.image}
        isExpired={delayedProps.isExpired}
      />
    );
  if (delayedProps.humanWin || delayedProps.vampireWin)
    return (
      <EndGame
        player={player}
        message={delayedProps.message}
        role={delayedProps.role}
        image={delayedProps.image}
        isExpired={delayedProps.isExpired}
        startGame={startGame}
        startGameWithIntro={startGameWithIntro}
        newGameAddPlayers={newGameAddPlayers}
        quitRound={quitRound}
        humanWin={delayedProps.humanWin}
      />
    );

  if (delayedProps.isExpired) return <Expired player={player} />;
};
export default Home;
