import Started from 'containers/Game/Started';
import { actions } from 'core/constants';
import { processText } from 'core/utils/string';
import React from 'react';
import { web } from 'submodules/sharedText/flavor';

const { morning, night } = web;
const {
  content: { priestVote },
} = morning;
const flavor = { ...morning, ...priestVote };

const { ATTEMPT_TO_RESURRECT } = actions;

const PriestBallot = ({
  player,
  socket,
  role,
  image,
  isExpired,
  playerVoted,
  message,
  gameSessionId,
}) => {
  const { candidates = [], _id: playerId } = player || {};

  const confirmSelection = (_playerVote) => {
    socket.emit(ATTEMPT_TO_RESURRECT, {
      gameSessionId,
      playerId,
      vote: _playerVote,
      confirm: true,
    });
  };

  const options = candidates?.map(({ _id, userName }) => {
    return {
      text: userName,
      onClick: () => confirmSelection(_id),
    };
  });

  return (
    <Started
      title={processText(night.title)}
      content={message || processText(flavor.instructions)}
      role={role}
      image={image}
      isExpired={isExpired}
      isLoading={playerVoted}
      options={!playerVoted ? options : []}
      isLongFlipped={options.length === 0}
    />
  );
};
export default PriestBallot;
