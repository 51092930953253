import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 6rem 0 4rem;
`;

const GameTitle = styled.div`
  font-size: 4rem;
  color: white;
`;
const VampirePartyTitle = () => <GameTitle>Vampire Party</GameTitle>;
export default VampirePartyTitle;
