import character_frame from 'art/character_frame.png';
import cullen from 'art/cullen.png';
import cullen_vamp from 'art/cullen_vamp.png';
// Characters
// TODO: rename file
import garlic_farmer_framed1 from 'art/garlic_farmer_framed1.png';
import librarian_framed1 from 'art/librarian_framed1.png';
import librarian_framed2 from 'art/librarian_framed2.png';
import mayor_framed1 from 'art/mayor_framed1.png';
import mayor_framed2 from 'art/mayor_framed2.png';
import priest_framed1 from 'art/priest_framed1.png';
import priest_framed2 from 'art/priest_framed2.png';
import vampire from 'art/vampire.png';
import vampire2 from 'art/vampire2.png';
import death_mode from 'art/death_mode.png';
import human from 'art/human.png';
import ashes2 from 'art/ashes2.png';
import cullen_ashes from 'art/cullen_ashes.png';
import { roles } from 'core/constants';
import React from 'react';
import styled from 'styled-components';

const {
  GARLIC_FARMER,
  PRIEST,
  LIBRARIAN,
  MAYOR,
  CULLEN,
  VAMPIRE,
  HUMAN,
} = roles;

const imageWidthRem = '13';
const imageWidthRemLarge = '15';
const CharacterFrame = styled.img.attrs(() => ({
  src: character_frame,
  alt: 'Frame with vampire teeth designs',
}))`
  width: ${imageWidthRem}rem;
  height: auto;
  position: absolute;
`;
const DeathMode = styled.img.attrs(() => ({
  src: death_mode,
  alt: 'Transparent curtain draped over frame with words R.I.P. written it',
}))`
  width: 21.25rem;
  height: auto;
  position: absolute;
  top: -1.625rem;
  z-index: 2;
`;

const CharacterContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: ${imageWidthRem}rem;
  height: ${imageWidthRem * 1.25}rem;
`;

const GarlicFarmer = styled.img.attrs(() => ({
  src: garlic_farmer_framed1,
  alt: 'Garlic Farmer',
}))`
  margin-top: 0.4063rem;
  margin-left: 0.25rem;
  width: ${imageWidthRem}rem;
  height: auto;
  position: absolute;
`;

const Priest = styled.img.attrs(() => ({
  src: priest_framed1,
  alt: 'Priest',
}))`
  margin-top: 0px;
  margin-left: 0px;
  width: ${imageWidthRem}rem;
  height: auto;
  position: absolute;
`;

const Priest2 = styled.img.attrs(() => ({
  src: priest_framed2,
  alt: 'Priest',
}))`
  margin-top: 0.425rem;
  margin-left: 0.375rem;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;

const Librarian = styled.img.attrs(() => ({
  src: librarian_framed1,
  alt: 'Librarian',
}))`
  margin-top: -0.875rem;
  margin-left: 0.1875rem;
  width: ${imageWidthRem}rem;
  height: auto;
  position: absolute;
`;

const Librarian2 = styled.img.attrs(() => ({
  src: librarian_framed2,
  alt: 'Librarian',
}))`
  margin-top: 0.375rem;
  margin-left: 0.75rem;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;

const Mayor = styled.img.attrs(() => ({
  src: mayor_framed1,
  alt: 'Mayor',
}))`
  margin-top: -0.5313rem;
  margin-left: -0.0625rem;
  width: ${imageWidthRem}rem;
  height: auto;
  position: absolute;
`;
const Mayor2 = styled.img.attrs(() => ({
  src: mayor_framed2,
  alt: 'Mayor',
}))`
  margin-top: 0.5625rem;
  margin-left: 0.625rem;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;

const Cullen = styled.img.attrs(() => ({
  src: cullen,
  alt: 'Cullen Turned Vampire',
}))`
  margin-top: 0.375rem;
  margin-left: 0.4375rem;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;
const VampCullen = styled.img.attrs(() => ({
  src: cullen_vamp,
  alt: 'Cullen Turned Vampire',
}))`
  margin-top: 1.1563rem;
  margin-left: 0px;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;
const Vampire = styled.img.attrs(() => ({
  src: vampire,
  alt: 'Vampire',
}))`
  margin-top: 0.4063rem;
  margin-left: -0.125rem;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;
const Human = styled.img.attrs(() => ({
  src: human,
  alt: 'Human',
}))`
  margin-top: 0.5625rem;
  margin-left: -0.125rem;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;

const Vampire2 = styled.img.attrs(() => ({
  src: vampire2,
  alt: 'Vampire',
}))`
  margin-top: 0.4688rem;
  margin-left: 0.2125rem;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;

const Ashes = styled.img.attrs(() => ({
  src: ashes2,
  alt: 'Ashes with Word Staked',
}))`
  margin-top: 0.425rem;
  margin-left: 0.5625rem;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;

const CullenAshes = styled.img.attrs(() => ({
  src: cullen_ashes,
  alt: "Cullen's Ashes",
}))`
  margin-top: 0.4375rem;
  margin-left: 0.75rem;
  width: ${imageWidthRemLarge}rem;
  height: auto;
  position: absolute;
`;

const CharacterImages = {
  [GARLIC_FARMER]: GarlicFarmer,
  [PRIEST]: Priest,
  [PRIEST + 2]: Priest2,
  [LIBRARIAN]: Librarian,
  [LIBRARIAN + 2]: Librarian2,
  [MAYOR]: Mayor,
  [MAYOR + 2]: Mayor2,
  [CULLEN]: Cullen,
  [VAMPIRE]: Vampire,
  [VAMPIRE + 2]: Vampire2,
  [VAMPIRE + 3]: VampCullen,
  [HUMAN]: Human,
  ashes: Ashes,
  cullenAshes: CullenAshes,
};

const Character = ({ role, isExpired }) => {
  let CharacterImage = CharacterImages[role];
  const isUndead = role.includes(CULLEN) || role.includes(VAMPIRE);
  if (isUndead && isExpired) {
    if (role === CULLEN) {
      CharacterImage = CullenAshes;
    } else {
      CharacterImage = Ashes;
    }
  }
  return (
    <CharacterContainer>
      <CharacterFrame />
      {!isUndead && <DeathMode hidden={!isExpired} />}
      {CharacterImage && <CharacterImage />}
    </CharacterContainer>
  );
};

export default Character;
