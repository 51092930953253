import Started from 'containers/Game/Started';
import { actions } from 'core/constants';
import { processText } from 'core/utils/string';
import React from 'react';
import { web } from 'submodules/sharedText/flavor';

const { night } = web;
const {
  content: { garlicVote },
} = night;

const { GIFT_GARLIC } = actions;

const GarlicFarmerVote = ({
  player,
  socket,
  role,
  isExpired,
  playerVoted,
  message,
  gameSessionId,
  image,
}) => {
  const { candidates, _id: playerId } = player || {};

  const giveGiftOfGarlic = (_playerVote) => {
    socket.emit(GIFT_GARLIC, {
      gameSessionId,
      playerId,
      vote: _playerVote,
      confirm: true,
    });
  };

  // TODO: Sort self to front
  const options = candidates?.map(({ _id, userName }) => {
    return {
      text: userName === player.userName ? 'Yourself' : userName,
      onClick: () => giveGiftOfGarlic(_id),
    };
  });

  return (
    <Started
      title={processText(night.title)}
      content={message || processText(garlicVote.instructions)}
      role={role}
      image={image}
      isExpired={isExpired}
      isLoading={playerVoted}
      options={!playerVoted ? options : []}
    />
  );
};
export default GarlicFarmerVote;
