import BatFlapping from 'components/BatFlapping';
import Button from 'components/Button/v2';
import GameText from 'containers/Game/GameText';
import React from 'react';
import styled from 'styled-components';
import Character from './Character';
import { getIsUndead } from 'core/utils/roles';

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
`;

const DeadSpace = styled.div`
  width: 100%;
  margin-top: 5rem;
`;

// TODO: Rename this
const Started = ({
  title,
  content,
  role,
  image,
  options = [],
  isLoading,
  isLong,
  isLongFlipped,
  isExpired,
}) => {
  const isUndead = getIsUndead(role);
  return (
    <Wrapper>
      {image && <Character role={image} isExpired={isExpired} />}
      {!isLoading && (
        <>
          {!isUndead && isExpired && <DeadSpace />}
          <GameText title={title} isLong={isLong} isLongFlipped={isLongFlipped}>
            {content}
          </GameText>
          {options.map(
            ({ onClick, text, disabled, selected, selectedPolls }) => {
              return (
                <Button
                  disabled={disabled}
                  onClick={onClick}
                  key={text}
                  selected={selected}
                  selectedPolls={selectedPolls}
                >
                  {text}
                </Button>
              );
            }
          )}
        </>
      )}
      {isLoading && <BatFlapping />}
    </Wrapper>
  );
};
export default Started;
