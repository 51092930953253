import Game from 'containers/Game';
import React, { useState } from 'react';

const NewDesigns = ({ match }) => {
  const { version = '' } = match?.params || {};
  const [isLoading, setIsLoading] = useState(false);
  const [validGameSession, setIsValidGameSessions] = useState(false);
  const [error, setError] = useState('');

  let handleEnterRoom = () => {
    setIsValidGameSessions(true);
  };
  let isV2 = version === 'isV2' || version === 'errorV2';
  if (version === 'errorV2' || version === 'error') {
    handleEnterRoom = () =>
      setError((error) =>
        error ? '' : "Room 'POOP' is invalid please try entering again."
      );
  }
  return <Game isNight ready isV2={isV2} />;
};

export default NewDesigns;
