import React from 'react';
import styled from 'styled-components';

export const TextStyle = styled.div`
  font-size: 1.4rem;
  text-align: center;
`;

const SpaceBetween = styled.div`
  margin-bottom: 1rem;
`;
export const ProcessArrayText = ({ children }) => {
  if (Array.isArray(children)) {
    return children.map((text) => <SpaceBetween>{text}</SpaceBetween>);
  }
  return children;
};

export const Text = ({ children, message }) => {
  if (children && message) {
    throw new Error('Only use children or message - not both');
  }
  if (message) {
    return (
      <TextStyle>
        <ProcessArrayText>{message}</ProcessArrayText>
      </TextStyle>
    );
  }
  return <TextStyle>{children}</TextStyle>;
};
