export const white = '#EFE1EF';
export const paperWhite = '#FFF';
export const darkGreen = '#292B30';
export const black = '#100E1C';
export const orange = '#B53A2D';
export const red = '#ff3300';

// I'm randomly assining these... all dnned to be updated
export const darkRed = '#5f0f40';
export const brightOrange = '#fb8b24';
export const pink = '#f28482';
export const teal = '#00a896';

export const colorLoop = [darkRed, brightOrange, pink, teal];
