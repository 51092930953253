// Buttons
import button from 'art/button.png';
import React, { useState } from 'react';
import styled from 'styled-components';

const inputWidthRem = 4.6;
const inputWidthRemLong = 6;
const inputHeightWithRem = 0.21701 * 12;

const Label = styled.div`
  color: white;
  font-size: 2rem;
  margin-bottom: 0.5rem;
`;

export const StyledInput = styled.input`
  @font-face {
    src: local('Gorey'), ur;(./fonts/Gorey.ttf) format('truetype');
  }
  background-color: transparent;
  font-family: Gorey;
  height: 2rem;
  font-size: 1.2rem;
  color: 'white';
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isLong }) => (isLong ? inputWidthRemLong : inputWidthRem)}rem;
  height: ${inputHeightWithRem}rem;
  border: none;
  background-image: url(${button});
  background-size: 100% 100%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`;

const Wrapper = styled.div`
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextInput = ({ label, isLong, value, onChange, upperCased, ...rest }) => {
  const [_value, setValue] = useState(value || '');
  const _onChange = (e) => {
    let value = e?.target?.value;
    if (upperCased) {
      value = value?.toUpperCase();
    }
    onChange(value);
    setValue(value);
  };

  return (
    <Wrapper>
      <Label htmlFor={label}>{label}</Label>
      <StyledInput
        isLong={isLong}
        id={label}
        value={_value}
        onChange={_onChange}
        {...rest}
      />
    </Wrapper>
  );
};

export default TextInput;
