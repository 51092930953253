import Started from 'containers/Game/Started';
import { processText } from 'core/utils/string';
import React from 'react';
import { web } from 'submodules/sharedText/flavor';

const { polls, day } = web;
const Polls = ({ role, image, message, isExpired }) => {
  return (
    <Started
      title={processText(day.title)}
      content={message || processText(polls.content[role])}
      role={role}
      image={image}
      isExpired={isExpired}
      isLongFlipped
    />
  );
};
export default Polls;
