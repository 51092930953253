import Started from 'containers/Game/Started';
import { actions } from 'core/constants';
import { processText } from 'core/utils/string';
import React, { useEffect, useState } from 'react';
import { web } from 'submodules/sharedText/flavor';

const { CONFIRM_READ } = actions;

const Intro = ({
  player,
  gameState,
  socket,
  gameSessionId,
  playerVoted,
  role,
  image,
  isExpired,
}) => {
  const { [gameState || 'intro']: flavor } = web;
  const { _id: playerId } = player || {};
  const [wasClicked, setWasClicked] = useState(playerVoted);
  useEffect(() => setWasClicked(playerVoted), [playerVoted]);

  const confirmRead = () => {
    if (!wasClicked) {
      socket.emit(CONFIRM_READ, {
        gameSessionId,
        playerId,
      });
      setWasClicked(true);
    }
  };

  if (!player) {
    return null;
  }
  const options = [
    {
      text: processText(flavor?.button),
      onClick: confirmRead,
    },
  ];
  return (
    <Started
      title={processText(flavor?.title)}
      content={processText(flavor?.content[role || 'default'])}
      role={role}
      image={image}
      isLoading={playerVoted}
      isExpired={isExpired}
      options={options}
      isLongFlipped
    />
  );
};

export default Intro;
