import Game from 'containers/Game';
import { roles } from 'core/constants';
import { processText } from 'core/utils/string';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { web } from 'submodules/sharedText/flavor';

const { directory } = web;
const flavor = { ...directory };

const Intro = ({ match }) => {
  const { role, night, version = '' } = match?.params || {};
  const isNight = night === 'night';
  const [isLoading, setIsLoading] = useState(false);
  const onClick = () => setIsLoading(true);
  const options = [
    {
      text: 'Done',
      onClick,
      id: 0,
    },
  ];

  const content = processText(flavor.description[role]);

  if (!Object.values(roles).includes(role)) {
    return <Redirect to="/" />;
  }

  return (
    <Game
      content={content}
      options={options}
      isNight={isNight}
      role={role + version}
      isLoading={isLoading}
      isLong
    />
  );
};

export default Intro;
