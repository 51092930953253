import web_background_day from 'art/web_background_day.png';
import web_background_night from 'art/web_background_night.png';
import { red } from 'core/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const Day = styled.img.attrs(() => ({
  src: web_background_day,
  alt: 'Cross-hatched black background',
}))`
  top: 0;
  height: auto;
  z-index: -2;
  position: fixed;
  width: 120%;
  height: auto;
  // large
  @media (min-width: 1400px) {
    width: 100%;
  }
  opacity: ${(props) => (props.show ? 1 : 0)};
  ${(props) => props.showAnimation && 'transition: opacity 0.5s linear 0.25s;'}
`;
// opacity: ${(props) => (props.show ? 1 : 0)};
const Night = styled(Day).attrs(() => ({
  src: web_background_night,
  alt: 'Cross-hatched black background',
}))``;

export const Background = ({ isDay, showAnimation }) => {
  return (
    <>
      <Day show={isDay} showAnimation={showAnimation} />
      <Night show={!isDay} showAnimation={showAnimation} />
    </>
  );
};

const JUST_CHILLING = 0;
const FADE_TO_BLACK = 1;
const FADE_FROM_BLACk = 2;
const PAUSE_ON_BLACK = 3;
const STARTING_SCREEN_BLACK = 4;

const fadeToBlackAndBack = keyframes`
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
`;

const fadeToBlackAnimation = css`
  animation: ${fadeToBlackAndBack} 0.5s ease-in 1;
`;

const fadeFromBlack = keyframes`
  0% {
    opacity: 1;
  }
 100% {
    opacity: 0;
  }
`;
const fadeFromBlackAnimation = css`
  animation: ${fadeFromBlack} 0.5s ease-in 1;
`;

const pauseOnBlack = keyframes`
  0% {
    opacity: 1;
  }
 100% {
    opacity: 1;
  }
`;

const pauseOnBlackAnimation = css`
  animation: ${pauseOnBlack} 0.9s ease-in 1;
`;

const startingScreenHackAnimation = css`
  animation: ${pauseOnBlack} 0.5s ease-in 1;
`;

const getAnimation = ({ animationState }) => {
  switch (animationState) {
    case FADE_TO_BLACK:
      return fadeToBlackAnimation;
    case FADE_FROM_BLACk:
      return fadeFromBlackAnimation;
    case PAUSE_ON_BLACK:
      return pauseOnBlackAnimation;
    case STARTING_SCREEN_BLACK:
      return startingScreenHackAnimation;
    default:
      return null;
  }
};

const Transition = styled(Night)`
  position: fixed;
  background-color: black;
  width: 120%;
  filter: brightness(25%);
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  ${getAnimation}
  // large
  @media (min-width: 1400px) {
    width: 100%;
  }
`;

const flashRed = keyframes`
  50% {
    opacity: 0;
  }
`;
const flashRedAnimation = css`
  animation: ${flashRed} 0.4s step-end infinite;
`;

const FlashRed = styled.div`
  position: fixed;
  background-color: ${red};
  width: 100%;
  height: 100%;
  z-index: 101;
  opacity: 0.7;
  pointer-events: none;
  ${flashRedAnimation}
`;

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
`;

const haveSelectedPropsChanged = (previousProps, currentProps) => {
  if (previousProps === null) return true;
  return Object.keys(previousProps).some((key) => {
    // Edge case - don't wanna refresh when children change
    if (key === 'children') return false;
    const hasChanged = previousProps[key] !== currentProps[key];
    if (hasChanged) {
      // console.log('haveSelectedPropsChanged', {
      //   key,
      //   previousValue: previousProps[key],
      //   newValue: currentProps[key],
      // });
      return true;
    }
    return false;
  });
};

export const useGameState = (props) => {
  // idea loading state
  const [animationState, setAnimationState] = useState(STARTING_SCREEN_BLACK);
  const [hasTransitionImgLoaded, setHasTransitionImgLoaded] = useState(false);

  const previousState = useRef(null);
  const isFirstRender = useRef(true);

  const setNextAnimation = useCallback(
    () =>
      setAnimationState((animationState) => {
        if (animationState === FADE_TO_BLACK) {
          const previousIsExpired = previousState.current.isExpired;
          previousState.current = props;
          // Player dead edge cases
          if (!previousIsExpired && props.isExpired) {
            return PAUSE_ON_BLACK;
          }
          return FADE_FROM_BLACk;
        }
        if (
          animationState === PAUSE_ON_BLACK ||
          animationState === STARTING_SCREEN_BLACK
        ) {
          return FADE_FROM_BLACk;
        }
        return JUST_CHILLING;
      }),
    [props]
  );
  useEffect(() => {
    if (
      hasTransitionImgLoaded &&
      haveSelectedPropsChanged(previousState.current, props)
    ) {
      if (previousState.current === null) {
        previousState.current = props;
      } else if (isFirstRender.current) {
        setAnimationState((animationState) => {
          if (animationState === JUST_CHILLING) {
            return FADE_TO_BLACK;
          }
          previousState.current = props;
          return FADE_FROM_BLACk;
        });
        isFirstRender.current = false;
      } else {
        setAnimationState(FADE_TO_BLACK);
      }
    }
  }, [props, hasTransitionImgLoaded]);

  const delayedProps = previousState.current || props;
  // console.log({ animationState });
  return {
    delayedProps,
    animationState,
    setNextAnimation,
    setHasTransitionImgLoaded,
    hasTransitionImgLoaded,
  };
};

// isNight is required for updates everything else passed in are just dependencies and BackgroundTransitions will watch for changes to them
const BackgroundTransitions = ({
  animationState,
  setNextAnimation,
  setHasTransitionImgLoaded,
}) => {
  return (
    <>
      <Transition
        animationState={animationState}
        onAnimationEnd={setNextAnimation}
        onLoad={() => setHasTransitionImgLoaded(true)}
      />
      {animationState === PAUSE_ON_BLACK && <FlashRed />}
    </>
  );
};
export default BackgroundTransitions;
