import Started from 'containers/Game/Started';
import { actions } from 'core/constants';
import { processText } from 'core/utils/string';
import React, { useState } from 'react';
import { web } from 'submodules/sharedText/flavor';

const { night } = web;
const {
  content: { librarianResearch },
} = night;

const { LIBRARIAN_RESEARCH } = actions;

const LibrarianResearch = ({
  player,
  socket,
  role,
  isExpired,
  playerVoted,
  message,
  image,
}) => {
  const { candidates, _id: playerId, vote } = player || {};
  const [playerVote, setPlayerVote] = useState(vote);

  const researchPlayer = (_playerVote) => {
    if (!playerVote) {
      socket.emit(LIBRARIAN_RESEARCH, {
        playerId,
        vote: _playerVote,
      });
      setPlayerVote(_playerVote);
    }
  };
  const options = candidates?.map(({ _id, userName }) => {
    return {
      text: userName,
      onClick: () => researchPlayer(_id),
    };
  });

  return (
    <Started
      title={!playerVoted && processText(night.title)}
      content={message || processText(librarianResearch.instructions)}
      role={role}
      image={image}
      isExpired={isExpired}
      options={!playerVoted ? options : []}
    />
  );
};
export default LibrarianResearch;
