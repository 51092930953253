import BackgroundTransitions, {
  Background,
  useGameState,
} from 'containers/Game/BackgroundTransitions';
import { actions, gameStateType } from 'core/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Content from './Content';

const {
  START_GAME,
  CREATE_PLAYER,
  FIND_PLAYER,
  FIND_SESSION,
  ENDED,
  PLAYER_INFO,
  UPDATED_SESSION_GAME_STATE,
  NEW_GAME,
  FRESH_START,
} = actions;
const {
  WAITING,
  INTRO,
  READY,
  STARTED,
  HUMAN_WIN,
  VAMPIRE_WIN,
  NIGHT_INTRO,
  NIGHT,
  MORNING_INTRO,
  MORNING,
  DAY,
  POLLS,
} = gameStateType;

const PLAYER_NAME = 'Player Name';
const PLAYER_ID = 'Player Id';
const GAME_SESSION_ID = 'Game Session Id';

const Home = ({ loading, socket }) => {
  const location = useLocation() || {};
  const history = useHistory() || {};
  const gameSessionHash = location?.hash?.slice(1);
  if (gameSessionHash) {
    localStorage.setItem(GAME_SESSION_ID, gameSessionHash);
  }
  const gameSessionId = localStorage.getItem(GAME_SESSION_ID);
  const [gameSessionValue, setGameSessionValue] = useState('');
  const [gameSession, setGameSession] = useState(null);

  const resetGameSession = useCallback(
    (e) => {
      e && e.preventDefault();
      history.replace('#');
      localStorage.setItem(GAME_SESSION_ID, '');
      setGameSession(null);
    },
    [history]
  );
  const _setGameSessionValue = (value) => {
    if (gameSessionId) {
      resetGameSession();
    }
    setGameSessionValue(value);
  };

  const [playerError, setPlayerError] = useState('');
  const [playerNameValue, setPlayerNameValue] = useState(
    localStorage.getItem(PLAYER_NAME) || ''
  );
  const _setPlayerNameValue = (value) => {
    // TODO: Bug fix... value ++ "spaces" creates "."
    if (value) {
      setPlayerNameValue(value);
      setPlayerError('');
    }
  };
  const [playerId, setPlayerId] = useState(localStorage.getItem(PLAYER_ID));
  const [player, setPlayer] = useState(null);
  const _setPlayerId = (_playerId) => {
    localStorage.setItem(PLAYER_ID, _playerId);
    setPlayerId(_playerId);
  };

  const _setPlayerName = (e) => {
    e.preventDefault();
    const trimmedName = playerNameValue.trim();
    setPlayerNameValue(trimmedName || '');
    localStorage.setItem(PLAYER_NAME, trimmedName);
    socket.emit(
      CREATE_PLAYER,
      {
        gameSessionId,
        playerName: trimmedName,
      },
      (_player) => {
        if (_player.status === 403) {
          setPlayerError(_player.error || _player.warn);
          resetGameSession();
          // Hack! (to show warning even though player input no longer exists)
          setGameSession({ warn: _player.warn });
        }
        if (_player.status !== 200) {
          setPlayerError(_player.error || _player.warn);
        } else {
          _setPlayerId(_player?.data?._id || '');
        }
      }
    );
  };

  const onSubmitGameSession = useCallback(
    (e) => {
      e.preventDefault();
      if (gameSessionValue) {
        history.replace('#' + gameSessionValue);
      }
    },
    [gameSessionValue, history]
  );

  useEffect(() => {
    if (!loading && gameSessionId) {
      socket.emit(
        FIND_SESSION,
        {
          gameSessionId,
          playerId,
        },
        (_gameSession) => {
          if (_gameSession.status === 403) {
            resetGameSession();
          }
          setGameSession(_gameSession);
        }
      );
    }
  }, [socket, gameSessionId, loading, playerId, resetGameSession]);
  useEffect(() => {
    let playerInfoKey = null;
    if (playerId) {
      socket.emit(FIND_PLAYER, {
        playerId,
      });
      playerInfoKey = `${playerId}-${PLAYER_INFO}`;
      socket.on(playerInfoKey, function (_player) {
        setPlayer(_player);
      });
    }
  }, [playerId, socket]);

  const hasSessionError = !!gameSession?.error || !!gameSession?.warn;

  let validGameSession = gameSession?.status === 200;
  // Kick players out when they are in the lobby - and game starts with out 'em.
  if (
    gameSession?.data?.gameState &&
    ![WAITING, READY].includes(gameSession?.data?.gameState) &&
    !player
  ) {
    validGameSession = false;
  }
  useEffect(() => {
    let updateSessionGameStateKey = null;
    let endedKey = null;
    if (gameSessionId && validGameSession) {
      updateSessionGameStateKey = `${gameSessionId}-${UPDATED_SESSION_GAME_STATE}`;
      socket.on(updateSessionGameStateKey, function (response) {
        const { gameState, message, condemned } = response;
        if (gameState) {
          setGameSession((g) => ({
            ...g,
            data: { ...g?.data, gameState, message, condemned },
          }));
        }
      });
    }
    if (!hasSessionError && validGameSession) {
      endedKey = `${gameSessionId}-${ENDED}`;
      socket.on(endedKey, function (_gameSession) {
        setGameSession(_gameSession);
        // Not resetting _playerId in this scenario sot that useEffect above isn't triggered
        // _setPlayerId('');
        setPlayer(null);
        socket.removeListener(updateSessionGameStateKey);
        socket.removeListener(endedKey);
      });
    }
    // cleanUp
    return () => {
      [updateSessionGameStateKey, endedKey].forEach((key) => {
        socket.removeListener(key);
      });
    };
  }, [socket, gameSessionId, hasSessionError, validGameSession]);

  const gameState = gameSession?.data?.gameState || WAITING;
  // const message = gameSession?.data?.message; // Message sent to desktop, if ever needed

  const startGame = () => {
    socket.emit(START_GAME, { gameSessionId, skipIntro: true });
  };

  const startGameWithIntro = () => {
    socket.emit(START_GAME, { gameSessionId, skipIntro: false });
  };

  const newGameAddPlayers = () => {
    socket.emit(NEW_GAME, { gameSessionId });
  };
  const quitRound = () => {
    socket.emit(FRESH_START, { gameSessionId });
  };
  const waiting = gameState === WAITING || player === null;
  const ready = gameState === READY;
  // returning value, and using later. hacky
  const isIntro = [INTRO, NIGHT_INTRO, MORNING_INTRO].find(
    (intro) => intro === gameState
  );

  const hasStarted = gameState === STARTED;
  const isNight = gameState === NIGHT && player && !player.expired;
  const isMorning = gameState === MORNING && player && !player.expired;
  const isDay = gameState === DAY && player && !player.expired;
  const isPolls = gameState === POLLS && player && !player.expired;
  const humanWin = gameState === HUMAN_WIN && player;
  const vampireWin = gameState === VAMPIRE_WIN && player;

  const gameSessionError = gameSession?.error || gameSession?.warn;

  // const randomImage = player?.image && imageObj[player.image];
  const showLoginTransition = player?.userName;
  const gameContainerDependencies = {
    gameState,
    loading,
    vipReady: !!player?.vip && !!ready,
    validGameSession,
    showLoginTransition,
    preGame: ready || waiting,
    playerVoted: !!player?.voted,
    role: player?.role,
    image: player?.image,
    isExpired: !!player?.expired,
    message: player?.message || '',
    isIntro,
    hasStarted,
    isNight,
    isMorning,
    isDay,
    isPolls,
    vampireWin,
    humanWin,
  };
  const {
    delayedProps,
    animationState,
    setNextAnimation,
    setHasTransitionImgLoaded,
    hasTransitionImgLoaded,
  } = useGameState(gameContainerDependencies);
  const contentProps = {
    socket,
    delayedProps,
    onSubmitGameSession,
    _setGameSessionValue,
    gameSessionError,
    resetGameSession,
    gameSessionId,
    _setPlayerName,
    _setPlayerNameValue,
    playerNameValue,
    playerError,
    startGame,
    startGameWithIntro,
    player,
    newGameAddPlayers,
    quitRound,
  };
  // This is magic
  return (
    <>
      <BackgroundTransitions
        animationState={animationState}
        setNextAnimation={setNextAnimation}
        setHasTransitionImgLoaded={setHasTransitionImgLoaded}
      />
      <Background
        isDay={[MORNING_INTRO, MORNING, DAY, POLLS, HUMAN_WIN].includes(
          delayedProps.gameState
        )}
        showAnimation={delayedProps.isExpired}
      />
      {hasTransitionImgLoaded && <Content {...contentProps} />}
    </>
  );
};
export default Home;
