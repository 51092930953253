import Button from 'components/Button/v2';
import { processText } from 'core/utils/string';
import React from 'react';
import styled from 'styled-components';
import { web } from 'submodules/sharedText/flavor';
import VampirePartyTitle from './VampirePartyTitle';

const { waitingRoom, actions } = web;
const flavor = { ...waitingRoom, ...actions };

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 6rem 0 4rem;
`;

const ButtonContainer = styled.div`
  margin-top: 5rem; ;
`;

const StartGame = () => {
  return (
    <Wrapper>
      <VampirePartyTitle />
      <ButtonContainer>
        <Button onClick={() => alert('You clicked a button!')}>
          {processText(flavor.startGameWithIntro)}
        </Button>
        <Button onClick={() => alert('You clicked a button!')}>
          {processText(flavor.startGame)}
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};
export default StartGame;
