import Started from 'containers/Game/Started';
import { getIsMayor, getIsPriest } from 'core/utils/roles';
import { processText } from 'core/utils/string';
import React from 'react';
import { web } from 'submodules/sharedText/flavor';
import { getIsUndead } from 'core/utils/roles';

const { expired } = web;
const flavor = { ...expired };

const Expired = ({ player }) => {
  const { role, image, message } = player || {};
  const isPriest = getIsPriest(role);
  const isMayor = getIsMayor(role);
  // TODO: Add edge case text for cullen
  // Hacky fucking fix - Necessary for cullen so far
  const deathNote =
    !isPriest && !isMayor
      ? message || processText(flavor.content[role])
      : processText(flavor.content[role]);
  const isUndead = getIsUndead(role);

  return (
    <Started
      title={!isUndead && processText(flavor.title[role])}
      content={deathNote}
      role={role}
      image={image}
      isExpired
      isLongFlipped
    />
  );
};
export default Expired;
