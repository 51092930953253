import Button from 'components/Button/v2';
import Error from 'components/Error';
import { Form } from 'components/Form';
import TextInput from 'components/TextInput/v2';
import VampirePartyTitle, { Wrapper } from 'containers/Game/VampirePartyTitle';
import { processText } from 'core/utils/string';
import React from 'react';
import { web } from 'submodules/sharedText/flavor';

const { waitingRoom } = web;
const flavor = { ...waitingRoom };

const LoginForm = ({
  showSessionInput,
  onSubmitGameSession,
  onChangeGameSession, // _setGameSessionValue,
  gameSessionError, // gameSession?.error
  validGameSession,
  resetGameSession,
  gameSessionId,
  onSubmitTextInput, //_setPlayerName
  onChangeTextInput, // _setPlayerNameValue
  playerNameValue,
  playerError,
}) => {
  return (
    <Wrapper>
      <VampirePartyTitle />
      {!validGameSession && (
        <Form onSubmit={onSubmitGameSession}>
          <TextInput
            label={processText(flavor.roomCode)}
            onChange={onChangeGameSession}
            maxLength={4}
            upperCased
            autoFocus
          />
          <Button selected>{processText(flavor.enter)}</Button>
          <Error>{gameSessionError}</Error>
          <div style={{ paddingTop: '20px' }}>
            <p
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'red', fontSize: '1.5rem' }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfUZfdIr8ehFgLLRKg5PjzgRPvU_LOc6L61pT9vzdqIboKFcw/viewform"
            >
              Have you played the game?
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'red', fontSize: '1.5rem' }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfUZfdIr8ehFgLLRKg5PjzgRPvU_LOc6L61pT9vzdqIboKFcw/viewform"
            >
              Tell us what you think!
            </a>
          </div>
        </Form>
      )}
      {/* {validGameSession && (
        // TODO Add back hand
        <Form onSubmit={resetGameSession} row>
          <GameSessionText>
            <Label>{processText(flavor.roomCode)}</Label> {gameSessionId}
          </GameSessionText>
          <StyledButton>
            <Exit />
          </StyledButton>
        </Form>
      )} */}
      {validGameSession && (
        <Form onSubmit={onSubmitTextInput}>
          <TextInput
            label={processText(flavor.name)}
            onChange={onChangeTextInput}
            maxLength={20} // TODO: Consider requirements around this length
            value={playerNameValue}
            autoFocus
            isLong
          />
          <Button selected>{processText(flavor.playGame)}</Button>
          <Error>{playerError}</Error>
        </Form>
      )}
    </Wrapper>
  );
};
export default LoginForm;
