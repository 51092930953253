import Button from 'components/Button/v2';
import TextInput from 'components/TextInput/v2';
import { red } from 'core/styles';
import { processText } from 'core/utils/string';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { web } from 'submodules/sharedText/flavor';
import VampirePartyTitle from './VampirePartyTitle';

const { waitingRoom } = web;
const flavor = { ...waitingRoom };

export const Wrapper = styled.form`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 6rem 0 4rem;
`;

const StyledError = styled.div`
  font-size: 1.5rem;
  color: ${red};
  padding: 1rem 2rem;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.8s linear 50ms;
`;

const Error = ({ children }) => {
  const message = useRef(children);
  if (children) {
    message.current = children;
  }
  return (
    <StyledError
      show={children}
      onAnimationEnd={() => {
        message.current = null;
      }}
    >
      {message.current}
    </StyledError>
  );
};

const LoginForm = ({
  showLoading,
  onEnterRoom,
  onChangeGameSession,
  gameSessionId,
  onEnterName,
  validGameSession,
  showSessionInput,
  error,
  showVipButtons,
}) => {
  return (
    <Wrapper
      onSubmit={(e) => {
        if (showSessionInput) {
          onEnterRoom(e);
        } else {
          onEnterName(e);
        }
      }}
    >
      <VampirePartyTitle />
      {!showLoading && !showVipButtons && (
        <>
          {showSessionInput && (
            <>
              <TextInput
                label={processText(flavor.enterRoomCode)}
                onChange={onChangeGameSession}
                value={gameSessionId}
                maxLength={4}
                upperCased // deal with this
                autoFocus
              />
              <Button selected>{processText(flavor.enter)}</Button>
            </>
          )}
          {validGameSession && (
            <>
              <TextInput label="Name" isLong />
              <Button selected>{processText(flavor.playGame)}</Button>
            </>
          )}
          <Error>{error}</Error>
        </>
      )}
    </Wrapper>
  );
};
export default LoginForm;
