import { getRandomItem } from 'core/utils/logic';

export const getBooleanFromString = (value) => value !== 'false';
export const pluralize = (count) => (count === 1 ? '' : 's');
export const addCommasAndAnd = (list) => {
  if (list.length < 2) return list;
  return list.slice(0, -1).join(', ') + ' and ' + list[list.length - 1];
};
export const processText = (text, props) => {
  let _text = '';
  if (!text) {
    console.warn('Please provide a valid flavor string');
    return _text;
  }
  if (Array.isArray(text)) {
    _text = getRandomItem(text);
  } else {
    _text = text;
  }
  if (props) {
    _text = Object.entries(props).reduce((t, [key, value]) => {
      const updatedText = t.replace(`{${key}}`, value);
      return updatedText;
    }, _text);
  }
  if (_text.indexOf('{') > -1) {
    console.error('processText: Something went wrong', text, props);
  }
  return _text;
};
