import React from 'react';
import styled from 'styled-components';
import { black } from 'core/styles';
// Buttons
import button from 'art/button.png';
import button_disabled from 'art/button_disabled.png';
import button_selected from 'art/button_selected.png';
import red_button from 'art/red_button.png';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
`;

const fontSizeRem = 1.2;
const buttonWidthRem = 12;

export const StyledButton = styled.button`
  @font-face {
    src: local('Gorey'), ur;(./fonts/Gorey.ttf) format('truetype');
  }
  background-color: transparent;
  font-family: Gorey;
  font-size: ${(props) => (props.large ? fontSizeRem * 1.3 : fontSizeRem)}rem;
  color: ${black};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.large ? buttonWidthRem * 1.3 : buttonWidthRem)}rem;
  padding: 0.6rem 0.5rem 0.5rem 0.5rem;
  border: none;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  &:focus {
    background-image: url(${({ isRed }) => {
      if (isRed) {
        return red_button;
      }
      return button_selected;
    }})
  }
  background-image: url(${({ disabled, selected, isRed }) => {
    if (isRed) {
      return red_button;
    }
    if (selected) {
      return button_selected;
    }
    if (disabled) {
      return button_disabled;
    }
    return button;
  }});
  background-size: 100% 100%;
  margin-bottom: ${(props) => (props.large ? 0.75 : 0.5)}rem;
`;

const Button = ({ selectedPolls = [], ...rest }) => {
  const isRed = selectedPolls.length > 0;
  return <StyledButton isRed={isRed} {...rest} />;
};

export default Button;
