import Started from 'containers/Game/Started';
import { actions } from 'core/constants';
import { processText } from 'core/utils/string';
import React, { useEffect, useState } from 'react';
import { web } from 'submodules/sharedText/flavor';

const { CONFIRM_READ } = actions;
const { directory } = web;
const flavor = { ...directory };

const CharacterInfo = ({
  player,
  socket,
  gameSessionId,
  playerVoted,
  role,
  image,
  isExpired,
}) => {
  const { _id: playerId } = player || {};
  const [wasClicked, setWasClicked] = useState(playerVoted);
  useEffect(() => setWasClicked(playerVoted), [playerVoted]);

  const confirmRead = () => {
    if (!wasClicked) {
      socket.emit(CONFIRM_READ, {
        gameSessionId,
        playerId,
      });
      setWasClicked(true);
    }
  };
  if (!player) {
    return null;
  }
  const options = [
    {
      text: processText(flavor?.button),
      onClick: confirmRead,
    },
  ];
  return (
    <Started
      content={processText(flavor.description[role])}
      role={role}
      image={image}
      isLoading={playerVoted}
      isExpired={isExpired}
      options={options}
      isLong
    />
  );
};

export default CharacterInfo;
