import { roles } from 'core/constants';
const {
  VAMPIRE,
  GARLIC_FARMER,
  PRIEST,
  CULLEN,
  MAYOR,
  LIBRARIAN,
  HUMAN,
} = roles;

export const getIsVamp = (role) => role === VAMPIRE;
export const getIsCullen = (role) => role === CULLEN;
export const getIsUndead = (role) => getIsCullen(role) || getIsVamp(role);
export const getIsGarlicFarmer = (role) => role === GARLIC_FARMER;
export const getIsPriest = (role) => role === PRIEST;
export const getIsMayor = (role) => role === MAYOR;
export const getIsLibrarian = (role) => role === LIBRARIAN;
export const getIsHuman = (role) => role === HUMAN;
