import BatFlapping from 'components/BatFlapping';
import React from 'react';
import BackgroundTransitions, {
  Background,
  useGameState,
} from 'containers/Game/BackgroundTransitions';
import LoginForm from './LoginForm';
import Started from './Started';
import StartGame from './StartGame';

const Game = ({
  isNight,
  isLoading,
  ready,
  onEnterRoom,
  onEnterName,
  validGameSession,
  error,
  title,
  content,
  role,
  options,
  isLong,
  isLongFlipped,
  isExpired,
  name,
}) => {
  const inGame = validGameSession && name;
  const gameContainerDependencies = {
    isNight,
    isLoading,
    ready,
    validGameSession,
    title,
    content,
    role,
    name,
    inGame,
    isLong,
    isLongFlipped,
    isExpired,
  };
  const {
    delayedProps,
    animationState,
    setNextAnimation,
    setHasTransitionImgLoaded,
    hasTransitionImgLoaded,
  } = useGameState(gameContainerDependencies);
  return (
    <>
      <BackgroundTransitions
        animationState={animationState}
        setNextAnimation={setNextAnimation}
        setHasTransitionImgLoaded={setHasTransitionImgLoaded}
      />
      <Background
        isDay={!delayedProps.isNight}
        showAnimation={delayedProps.isExpired}
      />
      {hasTransitionImgLoaded && (
        <>
          {!delayedProps.ready && !delayedProps.inGame && (
            <LoginForm
              showLoading={delayedProps.isLoading}
              onEnterRoom={onEnterRoom}
              onEnterName={onEnterName}
              validGameSession={delayedProps.validGameSession}
              error={error}
            />
          )}
          useGameState
          {delayedProps.ready && <StartGame />}
          {delayedProps.inGame && (
            <Started
              title={delayedProps.title}
              content={delayedProps.content}
              role={delayedProps.role}
              options={!delayedProps.isExpired ? options : []}
              isLoading={delayedProps.isLoading}
              // Calculate next two base on gameState
              isLong={delayedProps.isLong}
              isLongFlipped={delayedProps.isLongFlipped}
              isExpired={delayedProps.isExpired}
            />
          )}
        </>
      )}
    </>
  );
};
export default Game;
