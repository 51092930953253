import BatFlapping from 'components/BatFlapping';
import Button, { ButtonContainer } from 'components/Button/v2';
import VampirePartyTitle, { Wrapper } from 'containers/Game/VampirePartyTitle';
import { processText } from 'core/utils/string';
import React from 'react';
import { web } from 'submodules/sharedText/flavor';

const { waitingRoom, actions } = web;
const flavor = { ...waitingRoom, ...actions };

const WaitingRoom = ({
  playerName,
  gameSessionId,
  vipReady,
  startGame,
  startGameWithIntro,
}) => {
  // May never put back
  // const text = vip
  //   ? processText(flavor.vipWelcomeText, { playerName, gameSessionId })
  //   : processText(flavor.welcomeText, { playerName, gameSessionId });

  return (
    <Wrapper>
      <VampirePartyTitle />
      {vipReady && (
        <ButtonContainer>
          <Button onClick={startGame}>{processText(flavor.startGame)}</Button>
          {/* <Button onClick={startGameWithIntro}>
            {processText(flavor.startGameWithIntro)}
          </Button> */}
        </ButtonContainer>
      )}
      {/* Warning: Can't perform a React state update on an unmounted component */}
      {!vipReady && <BatFlapping />}
    </Wrapper>
  );
};

export default WaitingRoom;
