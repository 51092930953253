import text_container from 'art/text_container.png';
import text_container_long from 'art/text_container_long.png';
import text_container_long_flipped from 'art/text_container_long_flipped.png';
import web_background_day from 'art/web_background_day.png';
import web_background_night from 'art/web_background_night.png';
import Title from 'components/Title/v2';
import React from 'react';
import styled from 'styled-components';

export const Background = styled.img.attrs(({ isNight }) => ({
  src: isNight ? web_background_night : web_background_day,
  alt: 'Cross-hatched black background',
}))`
  top: 0;
  height: auto;
  z-index: -2;
  position: fixed;
  width: 120%;
  height: auto;
  // large
  @media (min-width: 1400px) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  box-sizing: border-box; // keeping, cuz easier to measure this way
  width: 14.125rem;
  background-image: url(${({ isLong, isLongFlipped }) => {
    if (isLong) return text_container_long;
    if (isLongFlipped) return text_container_long_flipped;
    return text_container;
  }});
  background-size: 100% 100%;
  padding: 2rem 1.7rem 1.7rem 1.85rem;
  margin-bottom: 1rem;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const Sentence = styled.p`
  margin: 0.1875rem;
`;

const TextContent = ({ children }) => {
  if (!Array.isArray(children)) {
    return (
      <ContentContainer>
        <Sentence>{children}</Sentence>
      </ContentContainer>
    );
  }
  return (
    <ContentContainer>
      {children.map((line, i) => (
        <Sentence key={line + i}>{line}</Sentence>
      ))}
    </ContentContainer>
  );
};

export const GameText = ({ title, children, isLong, isLongFlipped }) => {
  if (!title && !children?.length) return null;
  return (
    <TextContainer isLong={isLong} isLongFlipped={isLongFlipped}>
      {title && <Title>{title}</Title>}
      {children && <TextContent>{children}</TextContent>}
    </TextContainer>
  );
};

export default GameText;
