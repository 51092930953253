export const gameStateType = {
  WAITING: 'waiting',
  READY: 'ready',
  INTRO: 'intro',
  STARTED: 'started',
  NIGHT_INTRO: 'nightIntro',
  NIGHT: 'night',
  MORNING_INTRO: 'morningIntro',
  MORNING: 'morning',
  DAY: 'day',
  POLLS: 'polls',
  VAMPIRE_WIN: 'vampireWin',
  HUMAN_WIN: 'humanWin',
};

export const roles = {
  VAMPIRE: 'Vampire',
  CULLEN: 'Cullen',
  GARLIC_FARMER: 'Garlic Farmer',
  PRIEST: 'Priest',
  HUMAN: 'Human Person',
  LIBRARIAN: 'Librarian',
  MAYOR: 'Mayor',
};

export const MINIMUM_NUMBER_OF_PLAYERS = 6;

export const actions = {
  GET_VAMPIRE_TARGET_POLLS: 'get-vampire-target-polls',
  FRESH_START: 'fresh-start',
  ENDED: 'ended',
  CREATE_PLAYER: 'create-player',
  NEW_GAME: 'new-game-same-players',
  FIND_PLAYER: 'find-player',
  FIND_SESSION: 'find-session',
  ON_UPDATE_VAMPIRE_POLL: 'on-update-vampire-target-polls',
  VAMPIRE_MESSAGE: 'vampire-message',
  PLAYER_INFO: 'player-info',
  START_GAME: 'start-game',
  UPDATED_SESSION_GAME_STATE: 'updated-session-game-state',
  VAMPIRE_HUNT: 'vampire-hunt',
  VOTE_TO_KILL: 'vote-to-kill',
  GIFT_GARLIC: 'give-garlic',
  ATTEMPT_TO_RESURRECT: 'attempt-to-resurrect',
  USE_MAYORAL_POWERS: 'use-mayoral-powers',
  LIBRARIAN_RESEARCH: 'research-shit-and-stuff',
  CONFIRM_READ: 'confirm-read',
};
