import Started from 'containers/Game/Started';
import { processText } from 'core/utils/string';
import React from 'react';
import { web } from 'submodules/sharedText/flavor';

const { morning, day } = web;

const Morning = ({ role, message, isExpired, image }) => {
  return (
    <Started
      title={processText(day.title)}
      content={message || processText(morning.content.default[role])}
      role={role}
      image={image}
      isExpired={isExpired}
      isLongFlipped
    />
  );
};
export default Morning;
