import React, { useState } from 'react';
import Game from 'containers/Game';

const Intro = ({ match }) => {
  const { role, night, title } = match?.params || {};
  const isNight = night === 'night';
  const [isLoading, setIsLoading] = useState(false);
  const onClick = () => setIsLoading(true);
  const options = [
    {
      text: 'Skip',
      onClick,
      id: 0,
    },
  ];

  return (
    <Game
      title={title}
      content={'Follow instructions on main screen.'}
      options={options}
      isNight={isNight}
      isLoading={isLoading}
      role={role}
      isLongFlipped
      validGameSession
      name
    />
  );
};

export default Intro;
