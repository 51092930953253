import Started from 'containers/Game/Started';
import { actions } from 'core/constants';
import { processText } from 'core/utils/string';
import React from 'react';
import { web } from 'submodules/sharedText/flavor';

const { morning, night } = web;
const {
  content: { mayorVote },
} = morning;

const { USE_MAYORAL_POWERS } = actions;

const PriestBallot = ({
  player,
  socket,
  role,
  image,
  isExpired,
  playerVoted,
  message,
  gameSessionId,
}) => {
  const { _id: playerId, hasSuperPower } = player || {};

  const confirmSelection = (_playerVote) => {
    socket.emit(USE_MAYORAL_POWERS, {
      gameSessionId,
      playerId,
      vote: _playerVote,
      confirm: true,
    });
  };

  const options = [
    {
      text: processText(mayorVote.usePower),
      onClick: () => confirmSelection(USE_MAYORAL_POWERS),
    },
    {
      text: processText(mayorVote.doNotUsePower),
      onClick: () => confirmSelection(''),
    },
  ];

  return (
    <Started
      title={processText(night.title)}
      content={message || processText(mayorVote.usePower)}
      role={role}
      image={image}
      isExpired={isExpired}
      isLoading={playerVoted}
      options={!playerVoted && hasSuperPower ? options : []}
      isLongFlipped={options.length === 0}
    />
  );
};
export default PriestBallot;
