import { red } from 'core/styles';
import React, { useRef } from 'react';
import styled from 'styled-components';

const StyledError = styled.div`
  font-size: 1.5rem;
  color: ${red};
  padding: 1rem 2rem;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.8s linear 50ms;
`;

const Error = ({ children }) => {
  const message = useRef(children);
  if (children) {
    message.current = children;
  }
  return (
    <StyledError
      show={children}
      onAnimationEnd={() => {
        message.current = null;
      }}
    >
      {message.current}
    </StyledError>
  );
};
export default Error;
