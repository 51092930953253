import Started from 'containers/Game/Started';
import { processText } from 'core/utils/string';
import React from 'react';
import { web } from 'submodules/sharedText/flavor';

const HumanWin = ({
  playerVoted,
  role,
  image,
  isExpired,
  player,
  startGame,
  startGameWithIntro,
  newGameAddPlayers,
  quitRound,
  humanWin,
}) => {
  const flavor = humanWin ? web.humanWin : web.vampireWin;
  const expiredKey = isExpired ? 'expired' : 'notExpired';
  const isVip = player?.vip;

  let options = [];
  if (isVip) {
    options = [
      {
        text: processText(web.actions.startGame),
        onClick: startGame,
      },
      {
        text: processText(web.actions.addPlayers),
        onClick: newGameAddPlayers,
      },
      {
        text: processText(web.actions.quit),
        onClick: quitRound,
      },
    ];
  }

  const text = isVip
    ? processText(web.actions.endGame)
    : processText(flavor.content?.[role]?.[expiredKey]);

  return (
    <>
      <Started
        title={processText(flavor.title[role])}
        content={[
          text,
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'red' }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfUZfdIr8ehFgLLRKg5PjzgRPvU_LOc6L61pT9vzdqIboKFcw/viewform"
          >
            Tell us what you think!
          </a>,
        ]}
        role={role}
        image={image}
        isExpired={isExpired}
        options={options}
        isLongFlipped
      />
    </>
  );
};
export default HumanWin;
