import React from 'react';
import styled from 'styled-components';

const TitleStyled = styled.h1`
  font-size: 1.5rem;
  text-align: center; // web specific
  padding: 0 5px;
  flex: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = ({ children, ...rest }) => {
  return (
    <Container>
      <TitleStyled>{'-'}</TitleStyled>
      <TitleStyled>{children.toLocaleUpperCase()}</TitleStyled>
      <TitleStyled>{'-'}</TitleStyled>
    </Container>
  );
};

export default Title;
