import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Wrapper, Background } from 'containers/Game/BackgroundTransitions';
import GameText from 'containers/Game/GameText';
import { roles } from 'core/constants';

const {
  GARLIC_FARMER,
  PRIEST,
  LIBRARIAN,
  MAYOR,
  VAMPIRE,
  CULLEN,
  HUMAN,
} = roles;

const STAGING = 'staging';

const DAY = 'day';
const NIGHT = 'night';

const VOTING = 'voting';
const INTRO = 'intro';
const LOGIN = 'login';
const START_GAME = 'startgame';
const END_GAME = 'endgame';
export const URLS = {
  VOTING: `/${STAGING}/${VOTING}/:role?/:night?/:version?`,
  INTRO: `/${STAGING}/${INTRO}/:role?/:night?/:title?`,
  CHARACTER_INFO: `/${STAGING}/character-info/:role?/:night?/:version?`,
  LOGIN: `/${STAGING}/${LOGIN}/:version?`,
  START_GAME: `/${STAGING}/${START_GAME}/:version?`,
  END_GAME: `/${STAGING}/${END_GAME}/:role?/:night?/:title?/:text?/:isVip?`,
};

const MyWrapper = styled(Wrapper)`
  margin-top: 3rem;
  margin-bottom 5rem;
`;

const Link = ({ to, children }) => {
  return (
    <NavLink to={to} key={to}>
      {children}
    </NavLink>
  );
};

const GarlicFarmer = () => {
  return (
    <MyWrapper>
      <Background isNight />
      <GameText title={'Start Game'} isLong>
        {[
          <Link to={`/${STAGING}/${START_GAME}/`}>Start Game screen</Link>,
          'Last updated 10/30/2020',
        ]}
      </GameText>
      <GameText title={'End Game'} isLong>
        {[
          <Link
            to={`/${STAGING}/${END_GAME}/${GARLIC_FARMER}/${DAY}/Win!/You've beaten the odds against these oversized mosquito!`}
          >
            Garlic Farmer Win
          </Link>,
          <Link
            to={`/${STAGING}/${END_GAME}/${PRIEST}/${DAY}/Lose.../You will not pray for the soulless admonitions that have defeated you.`}
          >
            Priest Loses
          </Link>,
          <Link
            to={`/${STAGING}/${END_GAME}/${'ashes'}/${DAY}/Win!/Beyond the grave you revel in your demonic friends triumph`}
          >
            Dead Vamp Win
          </Link>,
          <Link
            to={`/${STAGING}/${END_GAME}/${VAMPIRE}/${DAY}/Win!/You can't truly be shocked.  Humans are just stray cattle in the end...`}
          >
            Vamp Win
          </Link>,
          <Link
            to={`/${STAGING}/${END_GAME}/${
              VAMPIRE + 2
            }/${DAY}/Lose/I hope you enjoy eternal damnnation, because yer dead.`}
          >
            Vamp Loses
          </Link>,
          'Last updated 10/30/2020',
        ]}
      </GameText>
      <GameText title={'End Game With Play Again buttons'} isLong>
        {[
          <Link
            to={`/${STAGING}/${END_GAME}/${MAYOR}/${DAY}/Win!/${encodeURIComponent(
              'Click to play again.'
            )}/isVip`}
          >
            Garlic Farmer Win
          </Link>,
          'Last updated 10/30/2020',
        ]}
      </GameText>
      <GameText title={'Human'} isLong>
        {[
          <Link to={`/${STAGING}/${INTRO}/${HUMAN}/night/Intro`}>
            human intro
          </Link>,
          <Link to={`/${STAGING}/${VOTING}/${HUMAN}/day`}>Human death</Link>,
          'Last updated 10/30/2020',
        ]}
      </GameText>
      <GameText title={'Cullen Death'} isLong>
        {[
          <Link to={`/${STAGING}/${VOTING}/${CULLEN}/night`}>
            Cullen updated death
          </Link>,
          'Last updated 10/30/2020',
        ]}
      </GameText>
    </MyWrapper>
  );
};

export default GarlicFarmer;
